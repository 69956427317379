import React from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import christ from "../images/Christ.jpeg";
import mountcarmel from "../images/mount carmel.jpeg";
import cms from "../images/cms.jpeg";
import IFIM from "../images/ifim.jpeg";
import jos from "../images/josphs.jpeg";
import presidency from "../images/presidency.jpeg";
import msr from "../images/msr.jpg";
import kristu from "../images/kristu.jpeg";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#005e69", // Updated color
  borderColor: "#005e69", // Updated color
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#004e5d", // Slightly darker color on hover
    borderColor: "#004e5d", // Slightly darker color on hover
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#004e5d", // Slightly darker color on active
    borderColor: "#004e5d", // Slightly darker color on active
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#005e69"), // Updated color
  backgroundColor: "#005e69", // Updated color
  "&:hover": {
    backgroundColor: "#004e5d", // Slightly darker color on hover
  },
}));

const blogPosts = [
  {
    id: 1,
    image: christ,
    title: "Christ University Bangalore",
    description:
      "Christ University Bangalore India is ranked 212 by the QS Ranking 2025 in Bangalore ",
  },
  {
    id: 2,
    image: mountcarmel,
    title: "Mount Carmel College",
    description:
      "Established in 1948, Mount Carmel College is located in Bangalore, Karnataka",
  },
  {
    id: 3,
    image: cms,
    title: "Center For Management Studies",
    description:
      "Established in 2009, Center for Management Studies (CMS) is a part of Jain ",
  },
  {
    id: 4,
    image: IFIM,
    title: "IFIM School of Management",
    description:
      "IFIM Bangalore was set up in 2009 by the Centre for Development Education",
  },
  {
    id: 5,
    image: presidency,
    title: "Presidency College",
    description:
      "Established in 2000, Presidency College is an integral institution of Presidency Group",
  },
  {
    id: 6,
    image: kristu,
    title: "Kristu Jayanti College",
    description:
      "Kristu Jayanti College (popularly known as KJC) was established in 1999",
  },
  {
    id: 7,
    image: jos,
    title: "M.S. Ramaiah College ",
    description:
      "The faculty of the institute is highly experienced and provides students with expert guidance",
  },
  {
    id: 8,
    image: msr,
    title: "St. Joseph's College",
    description:
      "The faculty of the institute is highly experienced and provides students with expert guidance",
  },
];

function CourseCards() {
  const handleVisitUsClick = () => {
    window.location.href =
      "https://www.getmycollege.com/colleges?state=karnataka";
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f9f8f6" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          fontSize: "28px",
          marginBottom: 3,
          textAlign: "center",
          fontFamily: "serif",
          color: "black",
        }}
      >
        Top BBA Colleges
      </Typography>
      <Typography
        variant="p"
        sx={{
          textAlign: {md:"center",xs:"center"},
          fontFamily: "serif",
          color: "black",
          margin:{md:"20px",xs:'0px'},
          fontWeight:'bold'
        }}
      >
        Empowering future leaders with knowledge, innovation, and practicalexperience.
        Here are the Top BBA Colleges List Provided below.
      </Typography>
    
      <Grid container spacing={3}>
        {blogPosts.map((post) => (
          <Grid item xs={12} md={3} key={post.id} mt={5}>
            <Card
              sx={{
                boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
                borderRadius: 2,
                "&:hover": {
                  transform: "scale(1.0)",
                  boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  mt:'30px',
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={post.image}
                alt={post.title}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    marginTop: 1,
                    fontSize: "16px",
                    color: "#4d4d4d",
                  }}
                >
                  {post.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ marginTop: 1, fontSize: "15px", color: "#4d4d4d" }}
                >
                  {post.description}
                </Typography>
                <Stack direction="row" spacing={2} marginTop="20px">
                  <BootstrapButton
                    variant="contained"
                    onClick={handleVisitUsClick}
                  >
                    Visit Us
                  </BootstrapButton>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Stack direction="row" spacing={2} marginTop="20px" sx={{ justifyContent: "center" }}>
        <ColorButton variant="contained" onClick={handleVisitUsClick}>
          Explore More BBA Colleges
        </ColorButton>
      </Stack>
    </Box>
  );
}

export default CourseCards;
