import "./App.css";
import { Grid } from "@mui/material";
import CourseCards from "./components/CourseCards";
import ApplyNow from "./components/ApplyNow";
import About from "./components/About";
import Carousell from "./components/Couresell";
import Navbar from "./components/Navbar";
import Fotter from "./components/Footer";
import AutoPop from "./components/Autopop";
import { useEffect, useState } from "react";
function App() {
  const [isAutoPopupOpen, setIsAutoPopupOpen] = useState(false);
  const handleOpenAutoPopup = () => {
    const formSubmitted = sessionStorage.getItem("formSubmitted") === "yes";
    if (!formSubmitted) {
      setIsAutoPopupOpen(true);
    }
  };
  const handleCloseAutoPopup = () => {
    setIsAutoPopupOpen(false);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOpenAutoPopup();
    }, 15000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <Grid overflow={'hidden'}>
      <Navbar />

      <Carousell />
      <CourseCards />
      <About />
      <ApplyNow />
      <Fotter />
      <AutoPop isOpen={isAutoPopupOpen} onClose={handleCloseAutoPopup} />
    </Grid>
  );
}

export default App;
