import React from "react";
import { Typography, Grid, Box } from "@mui/material";
import aboutimg from '../images/Aboutus.jpg';

const AboutPage = () => {
  return (
    <Grid
      sx={{
        padding: '20px',
        fontWeight: "bold",
        fontSize: "36px",
        letterSpacing: "0.5px",
        textTransform: "uppercase",
        mb: 3,
        textAlign: 'center',
        height: '50vh',
        backgroundImage: `url(${aboutimg})`, // Correct way to apply background image
        backgroundSize: "cover", // Ensures the image covers the full container
        backgroundPosition: "center", // Ensures the image is centered
        color: "black", // Make the text white to contrast with the background
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: "24px", sm: "36px" }, // Adjust font size for small screens (xs) and up (sm)
          fontWeight: "bold",
          marginBottom: "20px",
        }} 
      >
        Premier BBA colleges in Bangalore
      </Typography>

      {/* Box for Medium devices */}
      <Box
        sx={{
          textAlign: "left",
          fontSize: "18px",
          lineHeight: "1.8",
          maxWidth: "800px",
          margin: "0 auto",
          marginBottom: {md:"50px",xs:'70px'},
          textTransform: "lowercase", // Apply lowercase text
          padding: {md:"20px",xs:"10px"},
          fontWeight: "bold",
          border: "1px solid #ccc", // Border for the box
          backgroundColor: "rgba(255, 255, 255, 0.8)", // Slight background color for the box
          borderRadius: "8px", // Rounded corners for the box
          display: { xs: 'block', md: 'block' }, // Ensure it works on all screens
        }}
      >
        <Typography variant="body1">
          Welcome to BBA College Website, home to future business leaders. Our Bachelor of Business Administration (BBA) program blends academic excellence with practical experience. We prepare students with the skills and knowledge needed to excel in the dynamic business world.
        </Typography>
      </Box>
    </Grid>
  );
};

export default AboutPage;
