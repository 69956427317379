import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Drawer,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import image from "../images/logo.jpg";

function Navbarr() {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#005e69',
        color: "#4d4d4d",
        boxShadow: "none",
        borderBottom: "1px solid #ccc",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between", // Space between title and other elements
          alignItems: "center",
        }}
      >
        {/* Left-Aligned Title */}
        <Typography
          variant="h1"
          fontWeight="bold"
          sx={{
            fontFamily: "serif",
            fontSize: "40px",
            color: "#4d4d4d",
            textAlign: "left",
            marginLeft: "20px", // Adjust as needed for spacing
          }}
        >
          <Grid
            sx={{
              width: "125px", // Set the desired width
              height: "100px", // Set the desired height
              borderRadius: "50%", // Make it circular
              overflow: "hidden", 
              padding: "10px"
            }}
          >
            <img
              src={image}
              alt="logoimg"
              style={{
                width: "100%", // Adjust to fill the circular container
                height: "100%", // Adjust to fill the circular container
                objectFit: "cover", 
                borderRadius:"20px"
              }}
            />
          </Grid>
        </Typography>

        {/* Center-Aligned Navbar */}
        <Box sx={{ display: { md: "flex", xs: "none" }, gap: "20px" }}>
          <Button
            sx={{
              color: "White",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Home
          </Button>
          <Button
            href="https://www.getmycollege.com/about-us"
            sx={{
              color: "White",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            About Us
          </Button>
          <Button
            href="https://www.getmycollege.com/colleges?state=karnataka"
            sx={{
              color: "White",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Top BBA Colleges
          </Button>
          <Button
            href="https://www.getmycollege.com/contact-us"
            sx={{
              color: "White",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Contact Us
          </Button>
        </Box>

        <Grid sx={{ display: { md: "none", xs: "flex" } }}>
          <IconButton
          sx={{color:'#ffff'}}
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      </Toolbar>

      <Drawer
        anchor="right"
        open={open}
        onClose={handleDrawerToggle}
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: "#fff",
            color: "#4d4d4d",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>
          <Button
            sx={{
              color: "black",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
            onClick={handleDrawerToggle}
          >
            Home
          </Button>
          <Button
            href="https://www.getmycollege.com/about-us"
            sx={{
              color: "black",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
            onClick={handleDrawerToggle}
          >
            About Us
          </Button>
          <Button
            href="https://www.getmycollege.com/colleges?state=karnataka"
            sx={{
              color: "black",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
            onClick={handleDrawerToggle}
          >
            Top BBA Colleges
          </Button>
          <Button
            href="https://www.getmycollege.com/contact-us"
            sx={{
              color: "black",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
            onClick={handleDrawerToggle}
          >
            Contact Us
          </Button>
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default Navbarr;
