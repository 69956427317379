import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Grid, Box, TextField, Button, Typography, useMediaQuery } from '@mui/material';
import image1 from '../images/msr.jpg';
import image2 from '../images/couresel2.jpg';
import image3 from '../images/couresel.jpg';
import { CheckCircle } from '@mui/icons-material';

const Carousell = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const isMobile = useMediaQuery("(max-width:500px)");

  function CustomPrevArrow(props) {
    return (
      <div
        {...props}
        style={{ ...props.style, left: '10px', zIndex: 1, fontSize: '24px', cursor: 'pointer' }}
      >
        &#9665;
      </div>
    );
  }

  function CustomNextArrow(props) {
    return (
      <div
        {...props}
        style={{ ...props.style, right: '10px', zIndex: 1, fontSize: '24px', cursor: 'pointer' }}
      >
        &#9655;
      </div>
    );
  }

  const slides = [
    {
      image: image2,
      text: (
        <>
          TOP BBA COLLEGES IN <br /> BANGALORE
        </>
      ),
    },
    {
      image: image3,
      text: (
        <>
          SHAPE YOUR FUTURE WITH THE <br /> BEST BBA PROGRAMS
        </>
      ),
    },
    {
      image: image1,
      text: (
        <>
          JOIN THE ELITE INSTITUTIONS <br /> OF EXCELLENCE
        </>
      ),
    },
  ];

  return (
    <Box sx={{ position: 'relative', width: '100%' }}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Grid key={index} sx={{ position: 'relative' }}>
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`} 
              style={{ width: '100%', height: '70vh', filter: 'brightness(90%)' }}

            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'rgba(0, 0, 0, 0.3)',
                zIndex: 1,
              }}
            />
            <Typography
          
              sx={{
                textAlign:'center',
                position: 'absolute',
                top: '35%',
                fontSize:{md:40,xs:30},
                left: '5%',
                color: 'white',
                fontWeight: 'bold',
                textShadow: '6px 6px 8px rgba(0, 0, 0, 0.6)',
                zIndex: 2,
              }}
            >
              {slide.text}
            </Typography>
          </Grid>
        ))}
      </Slider>
      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '5%',
            transform: 'translateY(-50%)',
            zIndex: 3,
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: { xs: '90%', sm: '70%', md: '40%', lg: '30%' },
            color: 'black',
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{
              marginBottom: 1,
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Apply for Top BBA Colleges
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginBottom: 1,
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
          >
            Up to 20 lakhs in scholarships for merit students is available in selected BBA colleges in Bangalore.
          </Typography>
          <TextField
            fullWidth
            placeholder="Name"
            variant="outlined"
            name="name"
            sx={{
              marginBottom: '10px',
            }}
          />
          <TextField
            fullWidth
            placeholder="Phone Number"
            variant="outlined"
            name="phone"
            sx={{
              marginBottom: '10px',
            }}
          />
        
        
          <Button
            variant="contained"
            startIcon={<CheckCircle />}
            fullWidth
            sx={{
              backgroundColor: '#005e69',
              '&:hover': {
                backgroundColor: '#004b54',
              },
            }}
          >
            Apply Now
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Carousell;
