import React from "react";
import { Box, Typography, Divider } from "@mui/material";


const Footer1= () => {
  return (
    <Box sx={{ backgroundColor: "#005e69", padding: "20px", textAlign: "center",fontWeight: 'bold' ,marginBottom:{md:0,xs:0}}}>
      <Divider sx={{ mb: 2 }} />
        <Typography variant="body2" color="White">
          © 2024 BBA College Pvt. Ltd. All Rights Reserved.
        </Typography>
      </Box>
    
  );
};

export default Footer1;