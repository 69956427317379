import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  InputLabel,
  TextField,
  Grid,
} from "@mui/material";

const ApplyNow = () => {
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });

  const handleOpen = () => setFormOpen(true);
  const handleClose = () => setFormOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    console.log(formData);
    handleClose(); // Close the dialog after submission
  };

  return (
    <Grid sx={{marginBottom:{md:0,xs:-4}}}>
    
      {/* Apply Now Section */}
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          padding: "30px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          maxWidth: "90%",
          margin: "30px auto",
        }}
      >
        <Typography
        
          sx={{
            fontSize:{md:30,xs:40},
            fontWeight: "bold",
            textAlign: "center", // Align to left
            marginBottom: "20px",
          }}
        >
          Explore BBA Colleges
        </Typography>

        <Typography paragraph sx={{ textAlign: {md:"center",xs:'justify'}, marginLeft:{ md:"150px",xs:'0px'}, marginRight: {md:"100px",xs:'0px'} }}>
          A <b>BBA college offers business administration courses</b>,
          providing students with foundational knowledge in management,
          marketing, finance, and entrepreneurship.
        </Typography>
        
        {/* List of BBA Colleges */}
        <Typography component="ul" sx={{ marginLeft: {md:"380px",xs:'0px'} }}>
          <li>RV University School of Computer Science and Engineering</li>
          <li>PES University College of Engineering</li>
          <li>BMS Institute of Technology and Management</li>
          <li>Ramaiah Institute of Technology</li>
          <li>Dayananda Sagar College of Engineering-DSCE</li>
          <li>Sir M Visvesvaraya Institute of Technology</li>
          <li>BNM Institute of Technology</li>
          <li>RNS Institute of Technology</li>
        </Typography>

        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            sx={{
              backgroundColor: "#005e69",
              color: "white",
              padding: "10px 20px",
              fontSize: "16px",
              borderRadius: "5px",
              transition: "0.3s",
              "&:hover": { backgroundColor: "#004752" },
            }}
            onClick={handleOpen}
          >
            Apply Now
          </Button>
        </Box>
      </Box>

      {/* Application Form Dialog */}
      <Dialog open={formOpen} onClose={handleClose}>
        <DialogContent
          sx={{
            border: "2px solid #A6AEBF",
            padding: "20px",
            minWidth: "400px",
          }}
        >
          <Typography
            variant="h6"
            textAlign="center"
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "15px",
            }}
          >
            Application for BBA College
          </Typography>
          <Divider sx={{ height: "2px", backgroundColor: "black", mb: 2 }} />
          <InputLabel>Name</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name"
          />
          <InputLabel>Phone Number</InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number"
          />
          <Box container justifyContent="center" mt={3} gap={2}>
            <Button
              sx={{
                backgroundColor: "black",
                color: "white",
                padding: "10px 20px",
                fontSize: "14px",
                borderRadius: "5px",
                "&:hover": { backgroundColor: "#333" },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#005e69",
                color: "white",
                padding: "10px 20px",
                fontSize: "14px",
                borderRadius: "5px",
                "&:hover": { backgroundColor: "#004752" },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ApplyNow;
